/* IndustryBox.css */

.industry-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.icon {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
}

.title {
    font-size: 1.2rem;
    font-weight: bold;
}
