/* src/App.css */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.navbar {
  display: flex;
  align-items: center;
  background-color: #333;
  padding: 8px;
  margin-bottom: -38px;
  color: white;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.logo {
  height: 50px;
  margin-right: 10px;
  margin-left: 8px;
}

.brand-text {
  display: flex;
  flex-direction: column;
}

.brand-name {
  font-size: 1.5em;
  color: #FFD700;
  padding: 2px;
  font-weight: bold;
}

.slogan {
  font-size: 0.6em;
  color: #ffffff;
  padding: 3px;
  display: flex;
}

.italic {
  font-style: italic;
}

.section {
  position: relative;
  text-align: left;
  color: white;
  padding: 20px 0;
  margin: 20px 0;
}

.section-image {
  width: 100%;
  height: 600px;
  max-height: 800px;
  object-fit: cover;
}

.section-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5); /* Optional: To make text readable over the image */
  padding: 20px;
  border-radius: 10px;
}

.section h1, .section p {
  margin: 0;
  padding: 10px 0;
}

.text-section {
  text-align: left;
  color: black;
  background-color: #333;
  padding: 50px 20px;
  margin: 20px 0;
}

.heading {
    color: #FFD700; /* Change this to your preferred color for headings */
    margin-left: 100px;
}

.subheading {
    color: #ffffff; /* Change this to your preferred color for subheadings */
    line-height: 25px;
    margin-left: 100px;
    margin-right: 50px;
    white-space: pre-line;
    text-align: justify;
}


.heading1 {
  color: #FFD700; /* Change this to your preferred color for headings */
  margin-left: 80px;
}

.subheading1 {
  color: #ffffff; /* Change this to your preferred color for subheadings */
  margin-left: 80px;
}

@media (min-width: 600px) {
  .navbar {
    flex-direction: row;
  }
}

.services-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service-card {
  background-color: #fff;
  border: 2px solid #FFD700;
  border-radius: 8px;
  height: 2.5in;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  text-align: left;
}

/* Styling the service card heading */
.service-card h2 {
  font-size: 1.5em; /* Adjust the font size as needed */
  margin: 10px 0; /* Adjust the margin as needed */
  padding: 5px 0; /* Adjust the padding as needed */
  color: #000000; /* Adjust the color as needed */
}

/* Styling the service card paragraph */
.service-card p {
  font-size: 1em; /* Adjust the font size as needed */
  margin: 10px 0; /* Adjust the margin as needed */
  padding: 5px 0; /* Adjust the padding as needed */
  color: #000000; /* Adjust the color as needed */
}

/* For desktops and larger devices */
@media (min-width: 1024px) {
  .service-card {
    width: calc(33.33% - 40px); /* Three columns */
  }
}

/* Add this to your App.css */

#how-we-serve .heading {
  color: #FFD700; /* Change to your preferred color */
  font-size: 1.5em; /* Change to your preferred font size */
  position: relative; /* Ensure the ::after pseudo-element is positioned relative to this heading */
}


.expertise-icon {
  display: flex;
  align-items: right;
  justify-content: right;
  margin-bottom: -50px; /* Adjust margin as needed */
  margin-top: 20px;
}


.tech-stack {
  background-color: #333;
  color: white;
  padding: 60px 0;
}

.tech-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tech-icon-box {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.tech-icon {
  width: 100px;
  height: 100px;
  margin: 20px;
  object-fit: contain;
}


.ourindustries {
  color: #000000; /* Change this to your preferred color for headings */
  text-align: center;
}

.industry-boxes {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
}

.industry-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.icon {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
}






.career {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.job-listing {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.job {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  width: calc(33.33% - 20px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.job h3 {
  margin-top: 0;
}

.job p {
  margin-bottom: 15px;
}

.job-icon {
  width: 50px;
  height: 50px;
  fill: #333; /* Adjust color as needed */
}


.apply-now {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  border: none;
  background-color: #007bff; /* Adjust colors */
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.apply-now {
  background-color: #ddbb00; /* Green color for Apply Now */
  margin-top: 5px;
}

.apply-now:hover,
.read-more:hover {
  opacity: 0.8;
}



/* Add the following CSS to App.css */

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-group {
  margin-bottom: 15px;
  margin-left: 50px;
  width: 50%;
}

.form-group label {
  display: block;
  color: rgb(0, 0, 0);
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #ddbb00;
  margin-left: 600px;
  color: white;
  border: none;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #ffd900;
}

.contact-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}


.contact-info {
  display: flex;
  justify-content: space-around;
  width: 100%;
  color: rgb(0, 0, 0);
  max-width: 2000px;
  margin-top: 50px;
  margin-bottom: 10px;
}

.info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-item img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}




.products {
  padding: 20px;
}

.products h2 {
  color: #007bff;
}

.products p {
  color: #333;
}

.product-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product-card {
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  max-width: 300px;
  overflow: hidden;
  text-align: left;
}

.product-card img {
  width: 100%;
  height: auto;
}

.product-info {
  padding: 20px;
}

.product-info h3 {
  color: #ddbb00;
}

.product-info p {
  color: #333;
}


.view-pdf-button {
  background-color: #ddbb00;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.view-pdf-button:hover {
  background-color: #ffd900;
}